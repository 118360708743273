<!--
 * @Author: lixianhao 
 * @Date: 2023-02-08 19:52:44
 * @LastEditors: leesioisme 864823609@qq.com
 * @LastEditTime: 2023-05-17 21:02:20
 * @FilePath: /InspirationDesign/client/src/views/resource/List.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="resource"
    v-infinite-scroll="getList"
    :infinite-scroll-disabled="loadDisabled"
    :infinite-scroll-distance="50"
  >
    <waterfall-list
      ref="waterfall"
      :data="list"
      :isSelectable="true"
      :selectBtns="selectBtns"
    ></waterfall-list>
    <p class="load-tips" v-if="loading">加载中...</p>
    <p class="load-tips" v-if="finished">没有多了</p>
  </div>
</template>

<script>
import {
  debounce,
  generateListQuery,
  parseTime,
  setOptions,
} from "../../utils/index";
import WaterfallList from "../../components/resources/WaterfallList.vue";
export default {
  name: "ResourcesList",
  data() {
    return {
      list: [],
      listQuery: generateListQuery(),
      finished: false,
      loading: false,
    };
  },
  computed: {
    status() {
      return this.$route.query.status;
    },
    type() {
      return this.$route.query.type;
    },
    category() {
      return this.$route.params.category;
    },
    categoryList() {
      return setOptions("category_list");
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    loadDisabled() {
      return this.loading || this.finished;
    },
    selectBtns() {
      return [
        {
          label: "取消收藏",
          icon: "el-icon-folder-remove",
          handleClick: (list, btnItem, btnIndex) => {
            this.cancelCollectGroup(list);
          },
        },
      ];
    },
  },
  components: {
    WaterfallList,
  },
  created() {
    this.getList();
  },
  methods: {
    parseTime,
    reGetList() {
      this.listQuery.pageNow = 1;
      this.list = [];
      this.getList();
    },
    getList: debounce(
      function () {
        this.loading = true;
        console.log("loading data");

        this.$get("/resources/myCollect", {
          ...this.listQuery,
          typeId: this.type,
          category: this.category,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);
          this.list = this.list.concat(res.data.list);
          this.finished = res.data.finished;
          this.loading = false;
          console.log("loading finished");
          this.listQuery.pageNow++;
        });
      },
      50,
      false
    ),
    cancelCollectGroup(list = []) {
      this.$post("/resources/cancelCollect", {
        ids: list,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);
        this.list = this.list.filter((v) => list.indexOf(v.orId) == -1);
        // list.forEach((v) => {
        //   let index = list.indexOf(v);
        //   index > -1 && this.list.splice(index, 1);
        // });

        this.$refs.waterfall.resetSelected();
      });
    },
  },
  mounted() {
    console.log("xxx");
  },
  watch: {
    category(newV) {
      this.reGetList();
    },
    type(newV) {
      console.log(newV);
      this.reGetList();
    },
    status(newV) {
      this.listQuery.status = newV;
      this.reGetList();
    },
  },
};
</script>

<style lang="scss" scoped>
.resource {
  padding-left: 20px;
  padding-right: 20px;

  .load-tips {
    text-align: center;
    color: #909399;
    padding: 10px 20px;
    font-size: 14px;
  }
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-button,
::-webkit-scrollbar,
::-webkit-scrollbar-corner {
  width: 0 !important;
}
</style>
