<template>
  <div class="waterfall" ref="waterfall" :style="{ paddingTop: waterfallListOffsetTop + 'px' }">
    <ul class="list" :class="{ 'is-show': selectedList.length > 0 }" :style="{
      width: listWidth,
      height: listHeight,
    }">
      <li class="list-item" v-for="(item, index) in listForUI" :key="index" :style="{
        height: item.height + 'px',
        left: item.left + 'px',
        top: item.top + 'px',
      }">
        <div class="poster" :style="{ height: item.height + 'px' }">
          <el-image lazy :src="item.poster && $store.state.assetsUrl + item.poster" alt=""
            @click="toggleSelected(selectedList.length > 0, item)"></el-image>
        </div>
        <div class="btns" v-if="business != 'self'">
          <span><img :src="Download" alt="" @click="download(item)" /></span>
          <span><img v-if="item.isCollect > 0" :src="CollectActive" alt="" @click="collectThis(item, index)" />
            <img v-else @click="collectThis(item, index)" :src="Collect" alt="" /></span>
        </div>
        <div class="progress">
          <p class="progress-inner" :style="{ width: (item.downloading || 0) + '%' }"></p>
        </div>
        <div class="select" v-if="isSelectable">
          <el-checkbox v-model="selectedList" :label="item.orId || item.rId || item.id">{{ "" }}</el-checkbox>
        </div>
      </li>
    </ul>
    <el-empty :image="EmptyIcon" v-show="listForUI.length == 0">
      <el-button type="primary" @click="goPost">去发布一个吧</el-button>
    </el-empty>
    <div class="select-confirm" :class="{ 'is-show': selectedList.length > 0 }" v-if="isSelectable">
      <div class="select-info">
        <span><i class="el-icon-close" @click="selectedList = []"></i>已选择：{{
          selectedList.length
        }}个</span>
      </div>
      <div class="select-funcs" v-for="(item, index) in selectBtns" :key="index">
        <span class="select-funcs-item" @click="triggerClick(item, index)"><i :class="item.icon"></i>{{ item.label
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyIcon from "../../assets/imgs/empty_icon.png";
import Collect from "../../assets/imgs/collect.png";
import CollectActive from "../../assets/imgs/collect_active.png";
import Download from "../../assets/imgs/download.png";
import { debounce } from "../../utils";
import { mapState } from "vuex";
export default {
  name: "WaterfallList",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    business: {
      type: String,
      default: "",
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    selectBtns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      Collect,
      CollectActive,
      Download,
      EmptyIcon,
      originData: [],
      columnsHeight: [], // 每个列的当前高度
      columnsMinHeightIndex: 0, // 当前高度最小的列
      waterfallWidth: window.innerWidth, // 页面总宽度 - 侧边栏的210
      perColumnWidth: 235, // 每列的宽度
      gapWidth: 25, // 列之间的间隔
      gapHeight: 40, // 列之间的间隔
      minWaterFallWidth: 300, // 定义最小的瀑布流宽度
      selectedList: [],
    };
  },
  computed: {
    ...mapState(["waterfallListOffsetTop"]),
    listWidth() {
      return (
        this.columnsHeight.length * (this.perColumnWidth + this.gapWidth) -
        this.gapWidth +
        "px"
      );
    },
    listHeight() {
      return Math.max(...this.columnsHeight) + "px";
    },
    // 页面元素重新定位
    listForUI() {
      return this.data.map((v, i) => {
        // console.log(this.columnsHeight);
        // 缩放系数 = 原始宽度 / 每列展示宽度(即元素宽度)
        let scaleCoefficient = v.naturalWidth / this.perColumnWidth;
        // 元素高度 = 原始高度 / 缩放系数
        v.height = v.naturalHeight / scaleCoefficient;

        if (v.height > 500) v.height = 500; // 最高为500px

        // 获取当前列的高度
        let columnHeight = this.columnsHeight[this.columnsMinHeightIndex];
        // 当前列原始高度 + 间隔
        v.top = columnHeight;
        // left值 = 当前列索隐 * 每列宽度 + 间隔
        v.left =
          this.columnsMinHeightIndex * (this.perColumnWidth + this.gapWidth);

        // 当前列高 + 新增元素高度
        let lastHeight = this.columnsHeight[this.columnsMinHeightIndex];
        let currColumnHeight = lastHeight + v.height + this.gapHeight;
        this.columnsHeight.length == 0
          ? this.columnsHeight.push(currColumnHeight)
          : this.columnsHeight.splice(
            this.columnsMinHeightIndex,
            1,
            currColumnHeight
          );

        // 当前列高最小值
        let minHeight = Math.min(...this.columnsHeight) || 0;
        // 找到最小值的列
        this.columnsMinHeightIndex = this.columnsHeight.indexOf(minHeight);

        return v;
      });
    },
  },
  methods: {
    // 计算列数并重新分配列
    calcColumnCount() {
      this.waterfallWidth = this.$refs.waterfall.clientWidth; // 根据页面大小重新计算列数
      let columnCount;

      // 如果当前页面的宽度小于最小宽度，默认展示1列
      // 否则根据列宽和间隔计算
      if (this.waterfallWidth <= this.minWaterFallWidth) {
        columnCount = 1;
      } else {
        columnCount = Math.floor(
          (this.waterfallWidth + this.gapWidth) /
          (this.perColumnWidth + this.gapWidth)
        );
      }
      let newColumnsHeight = [];
      for (let i = 0; i < columnCount; i++) {
        newColumnsHeight.push(0);
      }

      this.columnsHeight = newColumnsHeight;
      this.columnsMinHeightIndex = 0;
    },
    // 检测页面变化
    windowResize: debounce(
      function (e) {
        this.calcColumnCount();
      },
      1000,
      false
    ),

    // 下载图片
    download(item) {
      // downloading下载状态为非空时候，才能下载
      if (item.downloading !== null && item.downloading !== undefined) return;
      this.$set(item, "downloading", null);
      this.$post("/resources/download", {
        id: item.orId,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);

        let filePath = this.$store.state.assetsUrl + (res.data.filePath || item.filePath);
        let fileName = res.data.resourceName || item.resourceName;

        fetch(filePath)
          .then(response => response)
          .then(async (response) => {
            this.$message.success("开始为您下载，请不要关闭当前页面");
            const reader = response.body.getReader();
            // 获取总长度
            const contentLength = +response.headers.get("Content-Length");

            // 读取数据
            let receivedLength = 0; // 当前接收的字节数
            let chunks = []; // 接收到的二进制块的数组

            while (true) {
              const { done, value } = await reader.read();

              if (done) break;

              chunks.push(value);
              receivedLength += value.length;

              item.downloading = (receivedLength / contentLength * 100).toFixed(0) - 0;
            }

            let blob = new Blob(chunks);

            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName + filePath.substring(filePath.lastIndexOf("."));
            a.dispatchEvent(new MouseEvent("click"));
            a.remove();
            this.$message.success("下载成功")

            setTimeout(() => { item.downloading = null; console.log(item.downloading) }, 1000);
          }).catch(err => {
            console.log(err)
            this.$message.error("资源获取失败，无法为您下载");
          });
      });
    },
    // 跳转发布页面
    goPost() {
      this.$router.push("/redirect/resources/post?source=post");
    },
    // 出发按钮方法的统一入口
    triggerClick(item, index) {
      if (typeof item.handleClick == "function") {
        item.handleClick(this.selectedList, item, index);
      }
    },
    // 切换选中状态
    toggleSelected(canUse, item) {
      // 如果当前不是可选择状态，则不能使用
      if (!canUse)
        return window.open("/resources/detail?id=" + item.orId, "_blank");
      // 检测是否已经选中
      let hasSelected = this.selectedList.indexOf(item.orId);
      if (hasSelected > -1) {
        this.selectedList.splice(hasSelected, 1);
      } else {
        this.selectedList.push(item.orId);
      }
    },
    // 收藏/取消收藏
    collectThis(item, index) {
      this.$post("/resources/toggleCollect", {
        id: item.orId,
      }).then((res) => {
        if (res.code != 200) return this.$message.error(res.message);

        item.isCollect = res.data;
        this.$message.success(
          res.data == 1 ? "感谢您的收藏，(^.^)" : "我会更加努力的，(ಥ﹏ಥ)"
        );
      });
    },
    resetSelected() {
      this.selectedList = [];
      this.calcColumnCount();
    },
  },
  mounted() {
    this.calcColumnCount();
    window.addEventListener("resize", this.windowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResize);
  },
  watch: {
    data(newV, oldV) {
      this.calcColumnCount();
    },
    columnsHeight(newV) {
      // console.log(newV)
    },
  },
};
</script>

<style lang="scss" scoped>
.waterfall {
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1575px;
  margin: 0 auto;

  .list {
    position: relative;
    margin: 0 auto;

    .list-item {
      width: 235px;
      position: absolute;
      background-color: rgb(41, 45, 49);
      cursor: pointer;
      overflow: hidden;

      .poster {
        width: 100%;
        transition: 0.5s;
        max-height: 500px;
        overflow: hidden;

        img {
          width: 100%;
          display: block;
        }
      }

      .btns,
      .select {
        transition: 0.5s;
        z-index: -1;
        opacity: 0;
      }

      .btns {
        position: absolute;
        bottom: 5px;
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>span {
          position: relative;
          display: inline-block;
          width: 36px;
          height: 36px;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 28px;
            height: 28px;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: #fff;
            z-index: -1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      }

      .select {
        position: absolute;
        right: 5px;
        top: 5px;

        /deep/.el-checkbox__input:not(.is-checked) {
          .el-checkbox__inner {
            background-color: transparent;
          }
        }

        /deep/.el-checkbox__inner {
          border-radius: 50%;
        }

        /deep/.el-checkbox__label {
          display: none;
        }
      }

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: transparent;

        .progress-inner {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: #0f5bff;
          opacity: .8;
        }
      }
    }

    &.is-show,
    .list-item:hover {

      .btns,
      .select,
      .poster {
        z-index: 1;
        opacity: 1;
        transform: scale(0.98);
      }
    }
  }

  .select-confirm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: -100%;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 40px;
    background-color: #0f5bff;
    color: #fff;
    transition: 0.5s;
    z-index: 999;

    .select-info,
    .select-funcs-item {
      padding: 5px 10px;

      i {
        font-weight: bold;
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &.is-show {
      bottom: 0;
    }
  }
}
</style>
